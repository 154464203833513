/* <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Ubuntu:wght@300;400;500;700&display=swap" rel="stylesheet"> */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Ubuntu:wght@300;400;500;700&display=swap");
/* Globa css */
:root {
  --white: #fff;
  --black: #000;
  --blue: #3c56aa;
  --dark: #111827;
  --lgray:#f8f8f8;
  --gray: #485b6b;
  --linkblue: #1e53b7;
  --lgray: #f9fafb;
  --gray3: #f3f3f6;
  --gray4:#f8f8f8;
  --primary-font: "Inter", sans-serif;
  --secondary-font: "Ubuntu", sans-serif;
  --Main-Gradient: linear-gradient(93deg, #3b51a0 42.36%, #0ca4ae 100%);
  --Main-Gradient2: linear-gradient(83deg, #3b51a0 18.09%, #0ca4ae 77.46%);
  --gap: 6rem;
}

html {
  font-family: var(--primary-font);
  font-weight: 400;
  transition: background 0.3s linear;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: var(--primary-font);
  transition: background 0.3s linear;
}
a {
  cursor: pointer !important;
}
/* Gap */
.sec-padd-t {
  padding-top: var(--gap);
}

.sec-padd-b {
  padding-bottom: var(--gap);
}

.sec-padd {
  padding: var(--gap) 0 var(--gap) 0;
}

/* Gap end */

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  color: var(--linkblue);
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 400;
}
p {
  color: var(--gray);
}
img {
  max-width: 100%;
}
/* header css */
header {
  box-shadow: 0px 4px 4px #00000026;
  padding: 12px 00;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 9;
  margin-bottom: 1.5rem;
}
header .navbar-expand-lg {
  padding: 0;
}
header .navbar-nav a {
  font-weight: 400;
  color: #2e4b63;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: normal;
}
header .logo {
  height: 55px;
}
header .navbar-nav .dropdown-menu a {
  margin-top: 1.7rem;
  display: flex;
  gap: 1.6rem;
  background: transparent !important;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}
header .navbar-nav .dropdown-menu a span {
  gap: 1.5rem;
  display: flex;
  align-items: center;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  width: max-content;
  top: 5rem;
  transform: translateX(-30%);
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 4px 10px 0px rgba(46, 75, 99, 0.18);
  border: none;
}

header .navbar-nav .dropdown-menu a:last-child {
  margin-bottom: 1.7rem;
}
header .navbar-nav a:hover,
header .navbar-nav aactive {
  color: var(--blue);
  /* font-weight: 700; */
}
header .navbar-nav {
  gap: 2rem;
  align-items: center;
}
.b-btn {
  width: 130px;
  height: 44px;
  background: var(--dark);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  color: var(--white) !important;
}
.b-btn:hover {
  background-color: var(--black);
}
header .navbar-nav .b-btn:hover {
  background: var(--Main-Gradient);
}
header .right-nav .social-item a:hover {
  color: var(--Main-Gradient);
}
header .right-nav {
  align-items: center;
  gap: 0rem;
}
/* header .nav-item.dropdown:hover  .dropdown-menu{
display: block;
} */
.social-item {
  display: flex;
  align-items: center;
}
header .right-nav .b-btn {
  margin-right: 1.8rem;
}
header .right-nav a {
  color: var(--black);
}
header .right-nav .social-item a {
  font-size: 2.8rem !important;
}
header .right-nav .social-item a:last-child {
  font-size: 2.5rem !important;
}
header .link_a{
  padding: 0 !important;
  border: none;
}
footer {
  position: relative;
  padding-top: 5rem;
}
footer::before {
  content: "";
  width: 54px;
  height: 1px;
  left: 50%;
  transform: translateY(-50%);
  background: #b5babe;
  position: absolute;
}

footer ul li {
  display: flex;
  gap: 1rem;
  text-align: left;
  margin-bottom: 1.5rem;
}
footer h4,
footer ul li a {
  color: var(--dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
footer p {
  color: var(--gray);
  font-size: 1.4rem;
}
footer ul li .icon-div svg {
  color: var(--blue);
  font-size: 2.5rem;
}
.footer-submenu {
  flex-direction: column;
}
.footer-submenu li a {
  color: var(--gray);
  font-weight: 400;
  line-height: 24px;
}
.footer-submenu li {
  margin-bottom: 0.5rem;
}
.footer-submenu {
  margin-bottom: 2rem;
}
.footer-form .input-group {
  border: 1px solid #b5babe;
  height: 48px;
  border-radius: 10px;
}
.footer-form .input-group .form-control {
  font-size: 1.4rem;
  box-shadow: none;
  border: none;
  background: transparent;
}
.footer-form .input-group .btn-primary {
  background: var(--blue);
  height: 40px;
  font-size: 2rem;
  width: 40px;
  border-radius: 10px !important;
  margin: 4px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-form.was-validated .has-validation {
  border-color: red;
}
.flex-div {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 2.5rem;
}
.flex-div img {
  height: 80px;
}
.copy-footer {
  padding-bottom: 3rem;
}
.copy-footer p {
  text-align: center;
  font-size: 1.2rem;
  background: var(--white);
  z-index: 1;
}
.copy-footer p {
  position: relative;
}
.copy-footer p::after,
.copy-footer p::before {
  content: "";
  position: absolute;
  border: 1px solid #d3d6d8;
  width: 30%;
  height: 1px;
  top: 50%;
  right: 0;
  z-index: 0;
}
.copy-footer p::before {
  left: 0;
}

/* home page css */

.home-slider .swiper-slide img {
  width: 100%;
  border-radius: 2.5rem;
}
.home-slider .swiper-slide .slider-content {
  position: absolute;
  left: 6%;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  color: var(--white);
}
.home-slider .swiper-slide .slider-content h2,
.service-banner h2 {
  font-size: 4.8rem;
  font-family: var(--secondary-font);
  line-height: 52px;
  margin-bottom: 2rem;
  position: relative;
}
.home-slider .swiper-slide .slider-content h2::before,
.c-heading::before,
.b-heading::before {
  content: "";
  position: absolute;
  left: -2.5rem;
  height: 70%;
  top: 6%;
  width: 4px;
  background: var(--linkblue);
}
.home-slider .swiper-slide .slider-content h2::after,
.c-heading::after,
.b-heading::after {
  content: "";
  position: absolute;
  left: -2.5rem;
  height: 20%;
  top: auto;
  bottom: 6%;
  width: 4px;
  background: #00d0dd;
}
.home-slider .swiper-slide .slider-content p {
  font-size: 1.8rem;
  margin-bottom: 5rem;
  color: var(--white);
}
.service-banner ,.service-banner p{
  color: var(--white);
}
.service-banner {
  font-size: 1.8rem;
}
.home-slider .swiper-slide .slider-content img {
  height: 55px;
  width: auto;
  margin-bottom: 2rem;
}
.slider-button {
  display: flex;
  gap: 2rem;
}

.slider-button a,
.btn-style8 {
  color: var(--white);
  background: var(--blue);
  border-radius: 12px;
  padding: 1.1rem 2.8rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
}
.slider-button a:first-child,
.btn-style8 {
  font-weight: 700;
}
.slider-button a:first-child svg,
.btn-style8 svg {
  font-size: 1.8rem;
}
.slider-button a:last-child svg {
  font-size: 3.8rem;
  border: 1px solid var(--white);
  border-radius: 50%;
  padding: 0.5rem;
}
.slider-button a:last-child {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 26.513px;
}
.slider-button a:hover:first-child,
.btn-style8:hover {
  background: var(--Main-Gradient);
}
.slider-button a:hover:last-child {
  border: 1px solid var(--white);
}
.btn-style8 {
  width: 230px;
  height: 60px;
}
.home-slider .swiper-button-prev,
.home-slider .swiper-button-next {
  position: absolute;
  bottom: 3rem;
  left: 6%;
  top: auto;
  border: 2px solid var(--white);
  border-radius: 1rem;
  width: 40px;
  height: 40px;
}
.home-slider .swiper-button-prev:after,
.home-slider .swiper-button-next:after {
  color: var(--white);
  opacity: 1;
  font-size: 2rem;
}
.home-slider .swiper-button-next {
  left: 10%;
}
.home-slider .swiper-pagination-bullets {
  position: absolute;
  top: auto !important;
  bottom: 3.9rem !important;
  left: 0 !important;
  display: inline-block;
  transform: translateX(-30%);
  z-index: 1;
}
.home-slider .swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--white);
}

.c-heading {
  position: relative;
  font-size: 3rem;
  font-family: var(--secondary-font);
  color: var(--dark);
  margin-bottom: 1.5rem;
}
.m-heading {
  font-size: 2.4rem;
  font-family: var(--secondary-font);
  color: var(--dark);
  margin-bottom: 1.5rem;
}
.text-gray {
  color: var(--gray);
}
.custom-card {
  border: 1px solid #d9d9d9;
  padding: 2rem;
  border-radius: 20px;
  transition: color 5s linear;
  transition: background 5s linear;
}
.custom-card:hover {
  background: var(--Main-Gradient);
  color: var(--white);
}
.custom-card h4 {
  font-size: 1.8rem;
  color: var(--dark);
  font-weight: 700;
  margin: 2.2rem 0 1.1rem 0;
}
.custom-card p {
  color: #2e4b63;
  margin-bottom: 3rem;
}
.custom-card a {
  color: var(--linkblue);
  font-weight: 600;
}
.custom-card a svg {
  font-size: 2.5rem;
  margin-left: 6px;
  transition: 0.2s all ease-in-out;
}
.custom-card:hover h4,
.custom-card:hover p,
.custom-card:hover a {
  color: var(--white);
  transition: 0.2s all ease-in-out;
}
.custom-card .img-effect img {
  transition: 0.3s all ease-in-out;
}
.custom-card .img-effect {
  overflow: hidden;
  transition: 0.3s all ease-in-out;
  border-radius: 20px;
}
.custom-card:hover .img-effect img {
  transform: scale(1.1);
  transition: 0.3s all ease-in-out;
}
.custom-card:hover a svg {
  margin-left: 15px;
  transition: 0.2s all ease-in-out;
}
.card-slider {
  padding: 5rem 0;
  padding-bottom: 12rem;
}
.card-slider .swiper-wrapper {
  padding-bottom: 4rem;
}
.card-slider .swiper-pagination {
  position: absolute;
  bottom: 0;
}
.card-slider .swiper-pagination-clickable .swiper-pagination-bullet {
  background: #d9d9d9;
  opacity: 1;
}
.card-slider .swiper-pagination-clickable .swiper-pagination-bullet-active {
  background: #626262;
}
.card-slider .card-slider {
  padding-left: 3rem;
  padding-right: 3rem;
}
.card-slider .swiper-button-prev,
.card-slider .swiper-button-next {
  width: 40px;
  height: 40px;
  background: #fff;
  padding: 0.5rem;
  border: 1px solid #2e4b63;
  border-radius: 9px;
}
.card-slider .swiper-button-prev:after,
.card-slider .swiper-button-next:after {
  color: #2e4b63;
  font-size: 2rem;
}
.card-content {
  padding: 0 1rem;
}
.offer-section {
  background: #edf1f6;
  border-radius: 30px;
  padding-bottom: 22rem;
}
.para-m {
  font-size: 1.4rem;
}
.f-white {
  color: var(--white);
}
.offer-section .para-m {
  font-size: 1.4rem;
}
.trust-section {
  background: url(../src/Assets/Images/trust-banner.webp) center center/ cover;
  position: relative;
  padding: 5rem;
  border-radius: 40px;
  margin-top: -20rem;
}
.trust-section:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--Main-Gradient);
  opacity: 0.85;
  border-radius: 40px;
}
.trust-section div,
.trust-section p {
  z-index: 1;
  position: relative;
}
.trust-box h4 {
  color: var(--white);
  font-size: 3rem;
}
.trust-section .c-heading::after,
.trust-section .c-heading::before {
  display: none;
}
.border-img {
  margin-top: -3.3rem;
}
.w-card {
  background: var(--white);
  border-radius: 20px;
  text-align: center;
  padding: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
  position: relative;
}
.w-card img {
  margin-bottom: 2.4rem;
}
.w-card p {
  font-weight: 700;
  color: var(--black);
  width: 100%;
}
.w-card-section {
  position: relative;
}
.w-card-section::before {
  content: "";
  position: absolute;
  top: 9px;
  right: 0;
  background-image: url(./Assets/Images/circle1.svg);
  width: 100px;
  height: 100%;
  background-repeat: no-repeat;
  left: 96%;
  z-index: 0;
}
.w-card-section::after {
  content: "";
  position: absolute;
  top: 54%;
  left: -5%;
  background-image: url(./Assets/Images/circle2.svg);
  width: 128px;
  height: 100%;
  background-repeat: no-repeat;
}
.blue-f {
  color: var(--linkblue);
}
.img-collpase {
  text-align: right;
  position: relative;
}
.big-img img:first-child {
  outline: 6px solid var(--white);
  border-radius: 30px;
  padding: 1rem;
  z-index: 1;
  position: relative;
}
.small-img img:last-child {
  position: absolute;
  left: 10%;
  top: 70%;
  z-index: 2;
}
.img-collpase::after {
  content: "";
  position: absolute;
  top: -10%;
  right: 0;
  background-image: url(./Assets/Images/dots.svg);
  width: 140px;
  height: 100%;
  background-repeat: no-repeat;
  left: 88%;
  z-index: 0;
}
.small-img:last-child::after {
  content: "";
  position: absolute;
  top: 105%;
  left: 0%;
  background-image: url(./Assets/Images/circle3.svg);
  width: 128px;
  height: 115px;
  background-repeat: no-repeat;
}
.second-collpase {
  position: relative;
}
.second-collpase img {
  outline: none;
  padding: 0;
}
.btn-style1 {
  border-radius: 10px;
  border: 1px solid #2e4b63;
  font-size: 14px;
  font-weight: 600;
  padding: 1.05rem 2.1rem;
  color: var(--dark);
  transition: color 0.3s linear;
  transition: background 0.3s linear;
}
.btn-style1:hover {
  color: var(--white);
  background: var(--dark);
  padding: 1.05rem 2.2rem;
}
.service-div {
  background: var(--Main-Gradient);
  padding-left: 5rem;
  padding-right: 5rem;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}
.service-div::after {
  content: "";
  position: absolute;
  right: -4%;
  top: -16px;
  border-radius: 296px;
  background: linear-gradient(92deg, #2b449c 4.5%, #27aca4 100%);
  height: 110%;
  width: 296px;
  z-index: 0;
}
.service-content {
  display: flex;
  align-items: center;
  gap: 5rem;
}
.service-content img {
  height: 135px;
  width: auto;
}
.n-border::before,
.n-border::after {
  display: none;
}
.service-div {
  margin-top: -12rem;
}
.service-button {
  justify-content: end;
  display: flex;
  z-index: 1;
  position: relative;
}
.btn-style2 {
  width: 210.173px;
  height: 62.238px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark);
  color: var(--white);
  gap: 1rem;
  border-radius: 12px;
}
.client-card {
  border-radius: 20px;
  background: var(--lgray);
  padding: 3rem;
}
.client-card p {
  color: var(--black);
  line-height: 20px;
  font-size: 1.4rem;
}
.client-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.7rem;
}
.client-info img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.client-info p:first-child {
  margin-bottom: 0;
  font-weight: 700;
}
.flex-end {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}
.flex-heading {
  flex-basis: 60%;
}
.btn-style3 {
  border-radius: 61px;
  border: 1px solid #2e4b63;
  width: 119px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--dark);
  font-weight: 600;
  transition: border 0.3s linear;
}
.btn-style3:hover {
  border-radius: 50px;
  background: var(--white);
  border: 1px solid var(--white);
  transition: border 0.3s linear;
}
.blog-card {
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  padding: 1rem;
  transition: box-shadow 0.3s linear;
  background: var(--white);
}
.blog-card img {
  border-radius: 12px;
  width: 100%;
  margin-bottom: 2rem;
}
.blog-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 2rem;
}
.blog-info span {
  border-radius: 86px;
  background: #f3f4f6;
  padding: 8px 16px;
  font-size: 12px;
  color: var(--blue);
  font-weight: 700;
  line-height: 16px;
}
.blog-info p,
.blog-card p {
  color: var(--gray);
  margin-bottom: 0;
  font-size: 1.4rem;
}
.blog-card h4 {
  font-size: 1.8rem;
  color: var(--dark);
  margin-bottom: 1.5rem;
}
.blog-button {
  padding: 2.5rem 0;
  text-align: center;
}
.blog-button a,.blog-button button {
  font-size: 1.4rem;
  color: var(--linkblue) !important;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.blog-button a svg , .blog-button button svg{
  font-size: 2rem;
  margin-left: 5px;
  transition: margin 0.3s linear;
}
.blog-card:hover {
  border-color: transparent;
  box-shadow: 0px 16px 16px -13px rgba(17, 24, 39, 0.29);
  -webkit-box-shadow: 0px 16px 16px -13px rgba(17, 24, 39, 0.29);
  -moz-box-shadow: 0px 16px 16px -13px rgba(17, 24, 39, 0.29);
  transition: box-shadow 0.3s linear;
}
.blog-card:hover a svg ,.blog-card:hover button svg {
  margin-left: 10px;
  transition: margin 0.3s linear;
}

/* career page */
.b-heading {
  font-size: 4.8rem;
  color: var(--dark);
  line-height: 52px;
  font-family: var(--secondary-font);
  position: relative;
}
.btn-c {
  padding: 2rem 2.5rem;
  border-radius: 12px;
}
.btn-style4 {
  background: var(--blue);
  color: var(--white);
}
.btn-style4:hover {
  background: var(--Main-Gradient);
}
.careers-img1 {
  border-top-left-radius: 60px;
  width: 100%;
  height: 100%;
  border: 7px solid rgb(255 255 255 / 30%);
  padding: 1rem;
  z-index: 1;
  position: relative;
}
.careers-img2 {
  border-bottom-right-radius: 60px;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: 7px solid rgb(255 255 255 / 30%);
  z-index: 1;
  position: relative;
}

.careers-top {
  margin-top: -15rem;
}
.careers-gradient {
  border-radius: 30px 30px 0px 0px;
  background: var(--Main-Gradient2);
  z-index: 1;
  position: relative;
}
.careers-top {
  position: relative;
}
.careers-top::after,
.circle-bottom-left::before {
  content: "";
  position: absolute;
  top: -4px;
  right: 0;
  background-image: url(./Assets/Images/circle4.svg);
  width: 110px;
  height: 100%;
  background-repeat: no-repeat;
  left: 94%;
  z-index: 0;
}
.border2::before {
  background: #5affcc;
}
.border2::after {
  background: #111827;
}
.custom-card1 {
  position: relative;
  z-index: 2;
}
.custom-card1 .w-card {
  border-radius: 20px;
  border: 1px solid #d3d6d8;
  background: var(--white);
  height: 100%;
  text-align: left;
  display: block;
}
.custom-card1 .w-card img {
  border-radius: 10px;
  background: var(--blue);
  padding: 1rem;
  margin-bottom: 1rem;
}
.custom-card1 .w-card h4,
.custom-card1 .w-card p {
  color: var(--black);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 20px;
  margin: 1.5rem 0;
}
.custom-card1 .w-card p {
  font-weight: 400;
}
.custom-card1 .w-card:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s linear;
}
.margin-career {
  margin-top: -18rem;
}
.careers-gradient.sec-padd {
  padding-bottom: 10rem;
  padding-bottom: 10rem;
}
.bglight {
  background: #edf1f6;
}

.gap-custom {
  padding: 10rem 0;
  padding-bottom: 20rem;
  border-radius: 0 0 30px 30px;
}
.margin-career .img-collpase::after {
  left: 93%;
  top: -14%;
  z-index: 1;
}
.careers-img {
  text-align: right;
}
.list-items {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.list-items > div {
  width: 50%;
}
.flex-end-center {
  display: flex;
  align-items: center;
  justify-content: end;
}
.list-items {
  border-bottom: 1px solid #485b6b;
  margin-bottom: 2rem;
}
.list-items h4 {
  font-size: 18px;
  color: var(--blue);
  margin-bottom: 1.5rem;
}
.list-items p {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.s-link {
  font-size: 14px;
  color: var(--gray);
  font-weight: 400;
  transition: color 0.3s linear;
  line-height: 20px;
}
.s-link:hover {
  color: var(--linkblue);
  transition: color 0.3s linear;
}
.btn-style5 {
  font-size: 14px;
  color: var(--white);
  font-weight: 600;
  padding: 1.2rem 2rem;
  border-radius: 10px;
  background: var(--dark);
  transition: background 0.3s linear;
  transition: padding 0.3s linear;
}
.btn-style5:hover {
  background: var(--blue);
  transition: background 0.3s linear;
  transition: padding 0.3s linear;
  padding: 1.2rem 2.5rem;
}
.apply-div {
  width: 130px;
  text-align: center;
}
.bg-gradient2 {
  background: var(--Main-Gradient2);
}
.service-div.bg-gradient2::after {
  display: none;
}
.p-small {
  font-size: 1.4rem;
}
.left-circle {
  position: relative;
  overflow: visible;
  padding-left: 7rem;
  padding-right: 7rem;
}
.left-circle::before {
  content: "";
  position: absolute;
  top: -18%;
  left: -35px;
  background-image: url(./Assets/Images/dots.svg);
  width: 100px;
  height: 100%;
  background-repeat: no-repeat;
  z-index: 0;
  background-size: contain;
}
.career-form.footer-form .input-group .form-control {
  padding-left: 3rem;
  color: var(--black);
  box-shadow: none;
}
.career-form.footer-form .input-group .btn-primary,
.dark-btn {
  width: auto;
  font-size: 14px;
  color: var(--white);
  font-weight: 700;
  padding: 1rem 1.2rem;
  border: none;
  background-color: var(--dark);
}
.career-form.footer-form .input-group .btn-primary svg {
  margin-left: 6px;
  font-size: 2rem;
}
.career-form.footer-form .input-group .btn-primary:hover,
.dark-btn:hover {
  background: var(--blue);
}
.career-form.footer-form .input-group {
  border: none;
  background: #ffffff3a !important;
}
.apply-form {
  padding: 5rem;
  border-radius: 30px;
  background: #edf1f6;
}
.apply-form input,
.apply-form textarea {
  border-radius: 10px;
  border: 1px solid #b9b9b9;
  background: var(--white);
  box-shadow: none;
  padding: 1.2rem;
  color: #818488;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none !important;
  font-family: var(--primary-font);
}
.apply-form .input-group-text {
  color: #818488;
  font-size: 14px;
  font-family: var(--primary-font);
  background: var(--white);
  font-weight: 400;
  border-radius: 10px 0 0 10px;
}
.apply-form .input-group {
  border-radius: 10px;
  border: 1px solid #b9b9b9;
}
.apply-form .input-group input {
  border: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.apply-form label {
  color: var(--blue);
  font-size: 14px;
  font-weight: 700;
}
.flex-form {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
}
.flex-form > * {
  width: 50%;
}
.flex-form .form-control {
  width: auto;
  border-color: var(--black);
  color: var(--black);
  background: var(--white);
}
.apply-form .dark-btn {
  border-radius: 10px;
  padding: 1.2rem 2rem;
}
.gray-hover:hover {
  background: #d3d6d8;
  border: 1px solid #d3d6d8;
}
.careers-gallary img {
  margin-bottom: 1.5rem;
  width: 499px;
  width: 100%;
  height: 257px;
  object-fit: cover;
  border-radius: 2rem;
}
.big-gallary {
  height: 98% !important;
}
.bg-radius {
  border-radius: 30px;
}
.margin-15 {
  margin-top: -15rem;
}
.model-box h4 {
  color: var(--black);
  font-size: 18px;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1.8rem;
}

.model-box P {
  font-size: 1.4rem;
  color: var(--gray);
  margin-bottom: 3px;
}
.model-box .modal-content {
  border-radius: 30px;
  padding: 4rem;
}
.model-box .btn-close {
  border: 1px solid;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  padding: 1.2rem;
  opacity: 1;
}
.model-box .modal-header {
  border: none;
  padding: 0;
}
.filter-div {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: end;
}
.filter-div p {
  color: var(--blue);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0;
}
.filter-div .form-select {
  width: 133px;
  height: 40px;
  border-radius: 10px;
  box-shadow: none;
  border: 0.5px solid var(--gray);
  font-size: 1.4rem;
  color: var(--black);
}

.blog-post {
  border-radius: 20px;
  background: #edf1f6;
  padding: 2rem;
  margin-top: 2.5rem;
}
.img-post img {
  width: 210.182px;
  height: 204px;
  border-radius: 14px;
  object-fit: cover;
}
.main-div-post,
.post-info-blog {
  display: flex;
  gap: 4rem;
  justify-content: space-between;
}
.img-post {
  max-width: 200px;
  width: 100%;
}
.post-main-div .blog-info {
  justify-content: start;
  gap: 2.5rem;
}
.post-main-div .blog-info span {
  background: var(--white);
}
.post-content {
  width: 70%;
}
.post-content h4 {
  font-size: 1.8rem;
  color: var(--dark);
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
.post-content p {
  color: var(--dark);
}

.post-profile {
  display: flex;
  gap: 2rem;
  border-radius: 28px;
  background: var(--white);
  padding: 1rem;
  align-items: center;
}
.post-profile img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.post-profile h5,
.post-profile p {
  font-size: 1.2rem;
  color: var(--black);
  margin-bottom: 0;
}
.btn-style7 {
  border-radius: 10px;
  border: 1px solid #2e4b63;
  font-size: 14px;
  font-weight: 600;
  padding: 1.4rem 1.7rem;
  color: var(--dark);
  transition: padding 0.3s linear;
  background: transparent;
  outline: none !important;
}
.btn-style7:hover {
  background: var(--dark);
  color: var(--white);
  padding: 1.4rem 2.4rem;
  transition: padding 0.3s linear;
}
.blog-details {
  padding-top: 15rem;
}
.blog-details-img img {
  border-radius: 30px;
}

.blog-details .post-profile {
  width: 200px;
  float: right;
}
.blog-text p {
  color: var(--dark);
  margin-bottom: 1.5rem;
}
.margin-21 {
  margin-top: -21rem;
}
/* .navbar-collapse .dropdown-item{
  position: relative;
} */
.menu-hover-content , .inner-menu{
  display: none;
}
.navbar-collapse .dropdown-item:hover > .menu-hover-content {
  display: block;
}
.navbar-collapse .menu-hover-item:hover > .inner-menu{
  display: block;

}
.inner-menu ul{
  list-style: none;
  padding-left: 0;
}
.navbar-collapse .menu-hover-item .inner-menu ul li a{
  justify-content: flex-start;
}
.menu-hover-content {
  position: absolute;
  display: none;
  background: #000000d9;
  color: var(--gray);
  font-size: 1.2rem;
  width: 250px;
  padding: 10px 0;
  border-radius: 6px;
  text-wrap: wrap;
  left: 100.4%;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(46, 75, 99, 0.18);
  color: var(--dark);
  min-width: 300px;
  top: 0;
}
.inner-menu{
  min-width: 275px;
}
.menu-hover-item {
  display: flex;
  gap: 2rem;
  padding: 0 10px;
}
.menu-hover-item img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}
.menu-hover-item h5 {
  color: var(--dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.menu-hover-item p {
  color: var(--gray);
  font-size: 1.2rem;
  font-weight: 400;
  display: flex;
  gap: 1rem;
  align-items: baseline;
}
/* .menu-hover-content::before {
  content: "";
  position: absolute;
  top: 8px;
  border-bottom: 2px solid #000;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid #d8d8d899;
  border-bottom: 10px solid #3c56aa00;
  transform: rotate(229deg);
  left: -7px;
} */
.partner-slider .swiper-wrapper {
  transition-timing-function: linear;
}
/* end home page css */

/* CSR page design */
.page-banner {
  position: relative;
}
.page-banner::after {
  position: absolute;
  content: "";
  top: 0;
  left: 5px;
  width: 99%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(61deg, #000 5.35%, rgba(0, 0, 0, 0) 75.72%);
}
.page-banner .slider-content {
  z-index: 1;
}
.page-banner img {
  border-radius: 3rem;
}
.title-banner {
  margin-bottom: 0 !important;
  color: #5affcc !important;
  font-size: 1.4rem !important;
}
.page-banner.home-slider .swiper-slide .slider-content {
  top: 75%;
}
.shadow-div {
  border-radius: 20px;
  border: 0.5px solid #d3d6d8;
  background: var(--white);
  padding: 8rem;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
}
.shadowimg-div {
  position: relative;
}
.border-img-csr {
  border-radius: 30px;
  border: 6px solid #ebeef6;
  padding: 1.4rem;
}
.absolute-img {
  position: absolute;
  left: -20%;
  bottom: -10%;
}
.right-circle::after {
  content: "";
  position: absolute;
  top: -3rem;
  right: 0;
  background-image: url(./Assets/Images/dots.svg);
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  z-index: 0;
  background-size: contain;
}
.bottom-left-circle {
  position: relative;
}
.bottom-left-circle::after {
  content: "";
  position: absolute;
  bottom: -5rem;
  left: -8%;
  background-image: url(./Assets/Images/circle2.svg);
  width: 128px;
  height: 128px;
  background-repeat: no-repeat;
  z-index: -1;
}
.csr-card .w-card {
  box-shadow: 0px 0px 15px -12px rgba(0, 0, 0, 0.1);
  border: none;
  text-align: center;
  padding: 2.4rem;
  padding-top: 4rem;
}
.csr-card .w-card p {
  margin-bottom: 0;
}
.csr-card .w-card img {
  background: transparent;
}
.csr-card .w-card h4 {
  font-size: 1.6rem;
}
.bottom-r-dots::after {
  content: "";
  position: absolute;
  background-image: url(./Assets/Images/dots.svg);
  width: 115px;
  height: 115px;
  background-repeat: no-repeat;
  right: -3rem;
  z-index: -1;
  bottom: -5rem;
  background-size: contain;
}
.circle-top-left::before {
  content: "";
  position: absolute;
  background-image: url(./Assets/Images/circle-top.svg);
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  left: -4rem;
  z-index: 0;
  top: -2rem;
  background-size: contain;
}
.white-border {
  border-radius: 36px;
  border: 5px solid var(--white);
  padding: 1.6rem;
}
.circle-slider.home-slider .swiper-button-prev,
.circle-slider.home-slider .swiper-button-next {
  top: 50%;
  border: none;
  background: #edf1f6;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.49));
  border-radius: 50%;
}
.circle-slider.home-slider .swiper-button-prev:after,
.circle-slider.home-slider .swiper-button-next:after {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 600;
}
.circle-slider.home-slider .swiper-button-prev {
  left: 2.4rem;
}
.circle-slider.home-slider .swiper-button-next {
  right: 2.4rem;
  left: auto;
}
.service-box {
  border-radius: 30px;
  border: 0.5px solid #d9d9d9;
  background: var(--white);
  padding: 5rem;
  box-shadow: 0px 10px 15px 6px rgba(0, 0, 0, 0.1);
  margin-top: -24rem;
  position: relative;
}
.big-heading {
  font-size: 4.8rem;
}
.service-box img {
  height: 250px;
  object-fit: contain;
}
.circle-bottom-left::before {
  left: -4rem;
  bottom: 0;
  top: 89%;
  height: 103px;
  z-index: -1;
}
.card-slider .custom-card {
  height: 100%;
  min-height: 440px;
}

/* end */

@media (min-width: 767px) and (max-width: 991px) {
  .client-section .col-md-4,
  .client-section .col-md-3,
  .custom-card1 .col-md-3 {
    width: 50%;
  }
  .inner-page-header .col-md-8,
  .apply-career-sec .col-md-6,
  .careers-div .col-md-6,
  .blog-page .col-md-8 {
    width: 100%;
  }
  .blog-page {
    padding-bottom: 2rem;
  }
  .blog-post .main-div-post,
  .blog-post .post-info-blog {
    flex-wrap: wrap;
  }
  .img-post,
  .img-post img,
  .post-content {
    width: 100%;
    max-width: 100%;
  }
  .post-info-blog {
    gap: 3rem;
  }
  .blog-post {
    padding-bottom: 4rem;
  }
  .blog-details .post-profile {
    float: left;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .home-slider .swiper-slide img {
    height: 400px;
    object-fit: cover;
  }
  .page-banner.home-slider .swiper-slide .slider-content {
    top: 70%;
    width: 94%;
}
  .home-slider .swiper-slide .slider-content {
    width: 100%;
  }
  .home-slider .swiper-slide .slider-content h2,
  .big-heading,
  .service-banner h2 {
    font-size: 3.2rem;
    line-height: 38px;
  }
  .home-slider .swiper-slide .slider-content p {
    margin-bottom: 3rem;
  }
  .home-slider .swiper-slide .slider-content {
    transform: translateY(-60%);
  }
  .home-slider .swiper-button-next {
    left: 12%;
  }
  .home-slider .swiper-pagination-bullets {
    transform: translateX(-20%);
  }
  .partner-slider.sec-padd-b {
    padding-bottom: 2rem;
  }
  .trust-section .sec-padd-t .col-md-3 {
    width: 41.66666667%;
  }
  .trust-section .sec-padd-t {
    padding-top: 3rem;
  }
  .w-card-section .col-md-2 {
    width: 33.33%;
  }
  .w-card-section::before {
    top: 2px;
    left: 89% !important;
  }
  .w-card-section::after {
    width: 86%;
    top: 80%;
    left: -3%;
  }
  .img-collpase::after {
    left: 64% !important;
  }
  .c-heading::after,
  .b-heading::after,
  .c-heading::before,
  .b-heading::before {
    left: -1.5rem;
  }
  .copy-footer p::after,
  .copy-footer p::before {
    width: 20%;
  }

  .careers-top::after {
    top: -13px;
    left: 87%;
  }
  .margin-career .img-collpase::after {
    left: 82%;
    top: -6%;
  }
}

@media (max-width: 991px) {
  header .navbar-nav {
    gap: 1rem;
  }
  header .right-nav {
    align-items: start;
  }
  .social-item {
    gap: 1.1rem;
  }
  .social-item a:last-child {
    margin-left: 2px;
  }
  header .navbar-nav {
    padding-top: 2rem;
  }
  .navbar-toggler {
    box-shadow: none !important;
  }
  .navbar-toggler .navbar-toggler-icon {
    width: 3rem;
    height: 3rem;
  }
  .navbar-toggler .navbar-toggler-icon {
    /* background: url(../src/Assets/Images/cross.svg); */

    background-repeat: no-repeat;
  }
  .navbar-toggler.collapsed .navbar-toggler-icon {
    background: url(../src/Assets/Images/bar-menu.svg);
    background-repeat: no-repeat;
  }
  .navbar-expand-lg #responsive-navbar-nav{
    background: #fff;
    box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
    -moz-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
    -webkit-box-shadow: rgb(0 0 0 / 23%) 0px 0px 5px 1px;
    padding: 2rem;
    z-index: 999;
    position: fixed;
    width: 84%;
    left: 0;
    top: 0%;
    height: 100% !important;
    transition: 0s ease-in-out !important;
    transition: 0s ease !important;
    transform: translateX(-35ch);
    transition-property: transform !important;
    transition-duration: 0.5s !important;
    transition-delay: 0.05s !important;
}
.navbar-expand-lg .navbar-collapse.show {
  transform: translateX(0ch) !important;
}
header .navbar-nav{
  align-items: start;
}
header .navbar-nav a{
  font-size: 1.6rem;
  margin: 0.5rem 0;
}
.mobile-subenu .accordion-item{
border: none;
}
.mobile-subenu .accordion-item button{
  font-weight: 400;
  color: #2e4b63;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0rem;
    display: flex;
    gap: 1.6rem;
    background: transparent !important;
    justify-content: space-between;
    padding-left: 0rem;
    padding-right: 0rem;
    box-shadow: none !important;
}
.mobile-subenu .accordion-button::after{
display: none;
}
.mobile-subenu .accordion-item button img{
  margin-right: 10px;
}
.mobile-subenu .accordion-item  .accordion-item button{
  font-size: 1.4rem;
}
.mobile-subenu .accordion-body .accordion-item .accordion-body .accordion-item button img{
  height: 50px;
}
.mobile-subenu .accordion-body .accordion-item .accordion-body .accordion-item   .accordion-body{
  border: 1px solid var(--gray3);
  margin: 1rem 0;
  margin-bottom: 2rem;
}
.mobile-subenu .accordion-body .accordion-item  .accordion-body button img{
  margin-right: 0px;
}
.mobile-subenu .accordion-item button{
  justify-content: flex-start;
}
.menu-hover-item ul{
  list-style: none;
  padding-left: 0;
}
.menu-hover-item ul li a{
  font-size: 1.4rem;
  display: flex;
  gap: 1rem;
  line-height: 22px;
  align-items: baseline;

}
.menu-hover-item ul li {
margin-bottom: 1.5rem;
}
.mobile-subenu .accordion-body h5,.mobile-subenu .accordion-body h5 a{
  font-weight: 400;
  font-size: 1.3rem;
  padding-left: 1rem;
}
}

@media (max-width: 767px) {
  .partner-slider {
    padding-bottom: 0;
  }
  .c-heading::before,
  .b-heading::before,
  .c-heading::after,
  .b-heading::after {
    left: 0;
    width: 3px;
  }
  .m-heading,
  .b-heading {
    padding-left: 1rem;
  }
  .b-heading {
    font-size: 3.2rem;
    line-height: 38px;
  }
  .c-heading {
    font-size: 2.5rem;
    padding-left: 1rem;
  }
  .border-img {
    margin-top: -1.6rem;
    padding-bottom: 0;
  }
  .border-img img{
    height: 20px;
  }
  .card-slider {
    padding-bottom: 7rem;
  }
  .trust-box h4 {
    font-size: 2rem;
  }
  .offer-section,
  .careers-top {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .w-card-section {
    padding-bottom: 0;
  }
  .w-card img{
    height: 50px;
    width: 50px;
  }
  .w-card p{
    font-size: 1.5rem;
  }
  .home-story-section {
    padding-top: 1rem;
  }
  .w-card-section::before {
    left: 83% !important;
    z-index: 0;
    background-size: contain;
    width: 70px;
    top: 18px;
  }
  
  .w-card-section::after {
    width: 70px;
    height: 128px;
    background-repeat: no-repeat;
    bottom: 0;
    background-size: contain;
    top: 95%;
    left: -15px;
  }
  .img-collpase::after {
    width: 100px;
    height: 100%;
    background-repeat: no-repeat;
    left: 80%;
    z-index: 0;
    background-size: contain;
    top: -8%;
  }
  .service-text {
    z-index: 1;
  }
  .service-div::after{
    width: 150px; 
    height: 150px;
  }
  .service-div{
    padding: 3rem; 
  }
  .service-content {
    flex-wrap: wrap;
    gap: 3rem;
  }
  .service-content img {
    height: 100px;
  }
  .service-button {
    justify-content: center;
    margin-top: 3rem;
  }
  .para-m.blue-f.sec-padd-t {
    padding-top: 1rem;
  }
  .img-collpase {
    margin-top: 4rem;
  }
  .home-blog .client-section {
    padding-top: 0rem;
  }
  .flex-end {
    flex-wrap: wrap;
  }
  .flex-heading {
    flex-basis: 100%;
  }
  .form-heading {
    margin-top: 2.5rem;
  }
  .copy-footer p::after,
  .copy-footer p::before {
    width: 20px;
  }
  .home-slider .swiper-slide .slider-content h2,
  .service-banner h2 {
    font-size: 1.8rem;
    line-height: 24px;
  }
  .slider-button{
    gap: 1rem;
  }
  .big-heading {
    font-size: 3.2rem;
  }
  .home-slider .swiper-slide .slider-content p {
    font-size: 1.3rem;
  }
  .home-slider .swiper-slide img {
    height: 400px;
    object-fit: cover;
    border-radius: 2.5rem;
  }
  .home-slider .swiper-slide .slider-content {
    width: 90%;
  }
  .home-slider .swiper-slide .slider-content img {
    height: 35px;
    border-radius: 0;
  }
  .home-slider .swiper-slide .slider-content p {
    margin-bottom: 3rem;
  }
  .slider-button a {
    padding: 0.8rem 1rem;
    font-size: 1.2rem;
  }
  .home-slider .swiper-slide .slider-content {
    top: 40%;
  }
  .home-slider .swiper-pagination-bullets {
    transform: translateX(0%);
  }
  .home-slider .swiper-button-next {
    left: 18%;
  }
  .home-slider .swiper-pagination-clickable .swiper-pagination-bullet{
    width: 6px;
    height: 6px;
  }
  .home-slider .swiper-button-prev:after, .home-slider .swiper-button-next:after{
    font-size: 1.5rem;
  }
  .home-slider .swiper-button-prev, .home-slider .swiper-button-next{
    width: 35px;
    height: 35px;
    border-width: 1px;
  }
  .trust-section{
padding: 3rem 2rem;
  }
  .trust-section div.sec-padd-t{
    padding-top: 3rem;
  }
  .text-end.col-md-3 {
    text-align: center !important;
    margin-top: 5rem !important;
  }
  .careers-img1,
  .careers-img2 {
    border: 5px solid rgb(255 255 255 / 30%);
  }
  .careers-top::after {
    left: 82%;
    top: 0;
    background-size: contain;
    width: 80px;
  }
  .margin-career .img-collpase {
    margin-top: 2rem;
  }
  .margin-career .img-collpase::after {
    left: 77%;
    top: -4rem;
  }
  .gap-custom {
    padding-top: 6rem;
  }
  .bglight .sec-padd-t {
    padding-top: 4rem;
  }
  .list-items p span {
    width: 70px;
  }
  .left-circle::before {
    top: -13%;
    left: 4px;
  }
  .company-culture-section {
    padding-top: 3rem;
  }
  .careers-gallary img {
    width: 100%;
    height: auto !important;
  }
  .careers-blog .client-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .g-5,
  .gx-5 {
    --bs-gutter-x: 0rem;
  }
  .blog-page {
    padding-bottom: 2rem;
  }
  .blog-post .main-div-post,
  .blog-post .post-info-blog {
    flex-wrap: wrap;
  }
  .img-post,
  .img-post img,
  .post-content {
    width: 100%;
    max-width: 100%;
  }
  .post-info-blog {
    gap: 3rem;
  }
  .blog-post {
    padding-bottom: 4rem;
  }
  .blog-details .post-profile {
    float: left;
  }
  .blog-details-img .c-heading.n-border {
    margin-bottom: 0;
  }
  .blog-text {
    text-align: justify;
  }
  footer ul {
    padding: 0;
  }
  .location-info p br {
    display: none;
  }
}

@media (max-width:400px) {
  .copy-footer p::after, .copy-footer p::before{
    width: 12px;
  }
}

/* contact us  */
.contact-ph {
  color: var(--white);
  font-size: 1.4rem;
  margin-bottom: 6rem;
}
.contact-row {
  border-radius: 30px;
  border: 0.5px solid #b5babe;
  background: var(--white);
  box-shadow: 0px 16px 16px -13px rgba(17, 24, 39, 0.29);
  -webkit-box-shadow: 0px 16px 16px -13px rgba(17, 24, 39, 0.29);
  -moz-box-shadow: 0px 16px 16px -13px rgba(17, 24, 39, 0.29);
  z-index: 1;
  position: relative;
}

.contact-img {
  background: url(../src/Assets/Images/contact-img.webp) center center / cover
    no-repeat;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 8rem;
  width: 100%;
  height: 100%;
}

.contact-number {
  display: flex;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.13);
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  align-items: center;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  padding: 1.5rem 1rem;
}
.contact-padd {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}
.contact-number svg {
  font-size: 2.5rem;
  color: var(--white);
}
.contact-number svg:first-child {
  color: #5affcc;
}
.contact-p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.contact-p1 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}

.submit-contact {
  background: var(--dark);
  border-radius: 10px;
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 1.4rem 4rem;
}
.contat-page-button {
  text-align: center;
  margin-top: 4rem;
}

.card-row {
  border-radius: 30px;
  background: var(--BG-Color, #edf1f6);
}

.card-overlay {
  padding-top: 25rem;
  margin-top: -18rem;
}

.sbox {
  border-radius: 20px;
  border: 0.5px solid #3b51a0;
  background: var(--Pure-White, #fff);
  width: 100%;
  position: relative;
  padding: 3rem 2rem 2rem 2rem;
  box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.1);
}

.sbox.wadev .sIcon {
  background: #3c56aa;
}

.sbox .sIcon {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translate(-50%);
  display: inline-block;
  height: 36px;
  width: 36px;
  line-height: 70px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.sbox .sIcon span {
  font-size: 24px;
  transition: 0.4s ease-in-out;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.sbox .sIcon span svg {
  font-size: 2rem;
}

.contact-form {
  padding: 5rem 8rem;
}

.contact-name {
  color: var(--dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.7rem;
}

.content {
  padding: 0rem 3rem !important;
}
.card-h {
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.card-p {
  color: #485b6b;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.contact-form .form-control {
  border-radius: 10px;
  border: 1px solid B5BABE;
  font-size: 1.4rem;
  color: #485b6b;
  padding: 1.4rem;
  box-shadow: none !important;
}
.contact-form .col-md-6 div > *,
.contact-form .col-md-12 div > * {
  margin-bottom: 2.2rem;
}
.conact-box-h {
  margin-bottom: 6rem;
  margin-top: 3rem;
}

@media (max-width: 1023px) {
  .contact-form {
    padding: 5rem;
  }
}

@media (max-width: 767px) {
  .card-handle {
    padding: 5rem 0rem 0rem 0rem !important;
    margin-top: 0;
  }
  .conact-box-h {
    margin-bottom: 4rem;
    margin-top: 1rem;
  }
  .contact-img {
    padding: 3rem 2rem;
  }
  .contact-number svg {
    font-size: 2rem;
  }
  .contact-form {
    padding: 5rem 2rem;
  }
}

/* Faq */

.faq .accordion-button:not(.collapsed) {
  font-family: var(--primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #000;
  background: transparent;
}
.faq .accordion-button{
  box-shadow: none !important;
  z-index: 1;
 
}
.faq .accordion-collapse{
  transition: all 0.5s;
}
.faq .accordion-item {
  font-family: var(--primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #485b6b;
  margin-bottom: 1rem;
  border-radius: 15px;
}

.faq .accordion-button {
  font-family: var(--primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #000;
  border-radius: 15px;
}

.faq .content {
  padding: 0 1rem !important;
}

.faq .c-headings {
  font-family: var(--primary-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  margin-bottom: 0.5rem;
}

.f-heading {
  color: #000;
  font-family: var(--secondary-font);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
}

.faq .c-paragraph {
  font-family: var(--primary-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #485b6b;
  margin-bottom: 1rem;
}

.faq .w-card-section {
  position: relative;
}

.faq .w-card-section::after {
  content: "";
  position: absolute;
  top: 1%;
  left: -8%;
  width: 128px;
  height: 100%;
  background-repeat: no-repeat;
}

.faq .w-card-section::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 100px;
  height: 100%;
  background-repeat: no-repeat;
  left: 96%;
  z-index: 0;
}

.faq .service-bottom {
  border-bottom: 1px solid #b5babe;
}

.faqs .faqs-row {
  position: relative;
}

.faqs .search {
  position: absolute;
  color: #b5babe;
  font-size: 16px;
  display: inline-block;
  top: -19px;
}

.faqs .search input {
  width: 350px;
  height: 36px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  color: #b5babe;
  font-family: var(--primary-font);
  border: 0;
}

.faqs .search input {
  text-indent: 10px;
}

.faqs .search .search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.faqs .search .search-icon {
  left: auto;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-story-section1 {
  padding: 6rem 10rem 0 10rem;
}
.lgray2{
  background: var(--gray4);
}
.c_col-6{
  width: 16.6%;
  flex-basis: 16.6%;
}
.shadow_card{
background: var(--white);
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding:3rem 2rem;
  text-align: center;
  transition: all 0.3s;
}
.shadow_card img{
  height: 50px;

}
.shadow_card h3{
  padding-top: 1.4rem;
  font-size: 1.6rem;
  color: var(--black);
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .home-story-section1 {
    padding-left: 0;
    padding-right: 0;
  }
}

/* About */

.about-c-heading {
  font-family: var(--primary-font);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #111827;
}

.about-c-paragraph {
  font-family: var(--primary-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #485b6b;
}

.about-img {
  color: #fff;
  padding: 5px;
  background: var(--blue);
  border-radius: 10px;
}

.aboout-content {
  color: #000;
  font-family: var(--primary-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.aboout-content-p {
  font-family: var(--primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #2e4b63;
}

.about-margin-career {
  margin-top: -20rem;
  padding: 0 15rem 15rem;
  position: relative;
}

.about-margin-career1 {
  margin-top: -21rem;
  padding: 0 15rem 5rem;
  position: relative;
}

.about-margin {
  border: 0.5px solid #d9d9d9;
  background: white;
  border-radius: 20px;
  padding: 4rem;
}

.about-margin1 {
  border: 0.5px solid #d9d9d9;
  background: white;
  border-radius: 30px;
  padding: 4rem;
}

.about-center {
  height: 160px;
  width: auto;
}

.about-tech-para::after {
  content: "";
  position: absolute;
  border: 0.1px solid #000;
}

.about-tech-para {
  padding-left: 3rem;
  position: relative;
}

.about-tech {
  padding-left: 3rem;
}
@media (max-width: 1280px) {
  .img-collpase::after {
    left: 78%;
  }
  .w-card-section::before {
    left: 92%;
  }
  .w-card-section::after {
    left: -16px;
  }
}
@media (max-width: 1023px) {
  .img-collpase::after {
    left: 72%;
  }
  .w-card-section::before {
    left: 92%;
  }
  .w-card-section::after {
    top: 77%;
    left: -29px;
  }
}
@media (min-width: 992px) and (max-width: 1300px) {
  .home-slider .swiper-slide .slider-content {
    width: 70%;
  }
  .home-slider .swiper-slide .slider-content h2,
  .service-banner h2 {
    font-size: 3rem;
    line-height: 45px;
  }
  .home-slider .swiper-slide .slider-content img {
    height: 45px;
  }
  .home-slider .swiper-slide .slider-content p {
    margin-bottom: 3rem;
  }
}

@media (max-width: 767px) {
  .about-margin-career1 {
    margin-top: -5rem;
    padding: 0 2rem 5rem;
  }

  .about-margin-career {
    margin-top: -9.5rem;
    padding: 0 2.5rem 10rem;
  }

  .about-margin {
    padding: 2rem;
  }

  .about-margin1 {
    padding: 2rem;
  }
  section.careers-div img{
    height: 400px;
    object-fit: contain;
    border-radius: 2rem;
    width: 100%;
  }
  .mission-about .about-img, .icon-about-sec .about-img{
width: auto;
height: auto;
  }
  .mission-about .sec-padd{
    padding-bottom: 0;
  }
  .company-culture-section h3{
     font-size: 1.8rem;
  }
  .company-culture-section p{
    font-size: 1.5rem;
  }
  .about-value-sec.sec-padd{
    padding-bottom: 0;
  }
  .apply-form{
    padding: 3rem;
  }
  .list-items > div {
    width: 100%;
}
.list-items{
  flex-wrap: wrap;
}
.flex-end-center{
  justify-content: start;
  margin-bottom: 3rem;
  padding-top: 1.5rem;
  gap: 2rem;
}
.shadow-div{
  padding: 3rem;
}
.page-banner.home-slider .swiper-slide .slider-content h2::after,
.page-banner.home-slider .swiper-slide .slider-content h2::before{
  left: -1.2rem;
}
.shadowimg-div{
  margin-top: 6rem;
}
.csr-slider .home-slider .swiper-slide img{
  height: auto;
}
.circle-slider.home-slider .swiper-button-next {
  right: 0.4rem; 
}
.circle-slider.home-slider .swiper-button-prev {
  left: 0.4rem;
}
.absolute-img,.circle-top-left::before{
  left: 0;
}
.bottom-left-circle::after{
  left: -1%;
}
.bottom-r-dots::after{
  right: 0;
}
.service-div.bg-gradient2.left-circle{
padding-bottom: 3rem;
}
.signup-section .sec-padd-t{
  padding-top: 1rem;
}
.faq .w-card-section::before{
  top: 54%;
  left: 76% !important;
}
.f-heading{
  font-size: 3.2rem;
}
.faq-calldiv{
  margin-bottom: 4rem;
}
.faqs .search{
  left: 50%;
  transform: translateX(-50%);
}
.service-banner .row{
  /* flex-direction: column-reverse; */
}
.service-banner p{
  font-size: 1.4rem;
}
.bg-logic h3{
  font-size: 1.8rem;
}
.service-btn{
  margin-left: 0 !important;
}
.service-right-img{
  height: 300px !important;
  margin: 0 auto;
}
}

@media(max-width:1025px){
  .contact-img{
    padding: 2rem;
  }
  .contact-form {
    padding: 5rem 3rem;
}
}

@media (min-width: 768px) and (max-width: 1200px) {
  .about-margin-career1 {
    margin-top: -12rem;
    padding: 0 4rem 5rem;
  }

  .about-margin-career {
    margin-top: -14rem;
    padding: 0 5rem 10rem;
  }
}

@media (min-width: 200px) and (max-width: 399px) {
  .about-tech-para::after {
    left: 1px;
    top: 3%;
    width: 8%;
  }

  .faqs .search input {
    width: 300px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .about-tech-para::after {
    left: 0px;
    top: 4%;
    width: 7%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .about-tech-para::after {
    left: -1rem;
    top: 5%;
    width: 7%;
  }
  .faqs .search input {
    width: 220px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .about-tech-para::after {
    left: -1rem;
    top: 8%;
    width: 4%;
  }
  .faqs .search input {
    width: 260px;
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .careers-top::after, .circle-bottom-left::before{
    left: 91%;
  }
  .margin-career .img-collpase::after{
    left: 87%;
  }
}

@media (min-width: 1401px) {
  .about-tech-para::after {
    left: -2rem;
    top: 10%;
    width: 4%;
  }
}


/* service pages inner */
@media (min-width:991px){
  .service-banner h2{
    line-height: 60px;
   }
   
}
.lgray{
  background: var(--lgray);
}
.service-banner{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.card-gradient { 
  background:  url(./Assets/Images/service/bg-pattern1.webp) center center/cover no-repeat;
              
}
/* .service-form{
  background: linear-gradient(90deg, rgba(49,74,171,1) 0%, rgba(74,66,160,1) 56%, rgba(17,160,176,1) 100%),  
  url(./Assets/Images/service/bg-contact1.webp) center center/cover no-repeat;
  background-blend-mode: multiply;
} */

.service-form{
  background:url(./Assets/Images/service/contact-img.webp) center center/cover no-repeat;
   
}

.service-get{
  background:  url(./Assets/Images/service/get-bg.webp) center center/cover no-repeat;
}
.service-form input{
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.8rem;
  outline: none;
  border: none;
}
.service-btn{
  width: 170px;
  height: 62.238px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2c51;
  outline: none;
  color: var(--white);
  gap: 1rem;
  border-radius: 12px;
  border: none;
}
.service-btn span{
  font-size: 2.5rem;
}
/* .card-gradient { 
  background: linear-gradient(90deg, rgba(49,74,171,1) 0%, rgba(74,66,160,1) 56%, rgba(17,160,176,1) 100%),  
              url(./Assets/Images/service/bg-pattern1.webp) center center/cover no-repeat;
              background-blend-mode: multiply;
} */

.custom-col{
  flex-basis: 20%;
  width: 20%;
}
.icon-box{
  height: 100%;
  background: var(--white);
  border-radius: 1.5rem;
  text-align: center;
  padding: 2rem 3rem;
}
.icon-box img{
  height: 40px;
}
.icon-box h3{
  color: var(--black);
  font-size: 1.7rem;
  padding-top: 1.6rem;
}
.icon-box p{
  color: #808080;
  font-size: 1.4rem;
  padding-top: 1rem;
}
.bg-logic{
  background: url(./Assets/Images/service/bg-pattern2.webp) left bottom / contain no-repeat;
}
.border-card{
  border: 2px solid #3c56a9;
  height: 100%;
  text-align: center;
  padding: 2rem;
  background: #ebf5f9;
}
.border-card h4{
  color: var(--black);
  font-size: 1.7rem;
  padding-top: 2rem;
}
.bg-logic p{
  color: #545454;
  font-weight: 500;
}
.bg-logic h3{
  color:#3c56aa;
  font-weight: 600;
  font-style: italic;
}
.btn-light{
  background: #5affcc;
  font-weight: 500;
  color: #2a2c51;
  width: 200px;
}
.btn-card{
  background: #e6e6e6;
  color: #121828;
  font-weight: 500;
  padding:1.8rem 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  transition: all 0.5s;
  cursor: pointer;
}
.btn-card:hover{
  background: #121828;
  color: var(--white);
}
.service-gradient h2,.service-gradient p{
  color: var(--white);
  text-align: center;
}
.service-gradient .col-md-4{
  text-align: center;
}
.service-card{
  position: relative; 
  color: var(--white);
}
.service-card img{
  width: 100%;
}
.service-card h4{
  position: absolute;
  top: 3rem;
  left: 10%;
  right: 10%;
  width: 80%;
  line-height: 30px;
}
.service-main-banner .col-md-6:last-child{
  text-align: right;
}

 /* Container for the flip card */
 .flip-card { 
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Add perspective to the flip effect */
}

/* Flip card container */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 200px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* Flip card face */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  left: 0;
  top: 0;
  padding: 2rem;
}

/* Flip card front */
 

/* Flip card back */
.flip-card-back {
  background-color: #ebf5f9;
  color: white;
  transform: rotateY(180deg);
  left: 0;
  top: 0;
}

/* Flip card container:hover */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.service-right-img{
  height: 400px;
  margin-left: auto;
  display: block;
}
.banner-logo{
  height: 60px;
  margin-bottom: 2rem;
}
.service-home-main p{
  font-size: 1.6rem;
}
.service-home-main{
  border-radius: 2.5rem;
}
.card_equal{
  height: 100%;
  background: #ebf5fa;
  border-radius: 1.5rem;
  text-align: center;
  padding: 2rem;
  border: 2px solid #7c8c95;

}
.card_equal img{
  height: 60px;
}
.card_equal h3{
  color: var(--black);
  font-size: 1.8rem;
  font-weight: 600;
  padding: 2rem 0;
  margin-bottom: 0;
}
.card_equal p{
  font-size: 1.5rem;
}
.service_home_talk .trust-section{
  margin-top: 0;
  padding: 6rem 5rem;
  background: url(./Assets/Images/service/aws/home-service-bg.webp) center center / cover no-repeat;
}
.service_home_talk p{
  color: var(--white);
}
.shadow_card:hover{
  background: #ebf5fa;
}
.border_blue_font h4{
  color: #3c56aa;
  font-size: 2rem;
  padding-top: 0;
  font-weight: 500;
  font-style: italic;

}
.border_blue_font{
  padding: 4rem 2rem;
}
.p-gray p{
  font-weight: 500;
}
.page_loader{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
}
.page_loader svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 300px;
  height: 300px;
}
.page_loader svg path{
  stroke: var(--blue);
}
.page_loader::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #edf1f6ef;
}
@media (max-width:1200px) {
  .custom-col,.c_col-6{
    flex-basis: 25%;
    width: 25%;
  }
}
@media (max-width:991px) {
  .custom-col,.c_col-6{
    flex-basis: 33.33%;
    width: 33.33%;
  }
}

@media (max-width:767px) {
  .custom-col,.c_col-6{
    flex-basis: 50%;
    width: 50%;
  }
  .icon-box{
padding: 2rem;
  }
  .icon-box h3{
    font-size: 1.6rem;
  }
  .icon-box p{
    font-size: 1.3rem;
  }
}
.paginationBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.paginationBtns li {
  list-style: none;
  margin: 0 5px;
}

.paginationBtns a {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.paginationBtns a:hover {
  background-color: #ddd;
}

.paginationBtns .previousBtn,
.paginationBtns .nextBtn {
  padding: 0.5rem 1.5rem;
}

.paginationBtns .previousBtn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.paginationBtns .nextBtn {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.paginationBtns .paginationActive a {
  background-color: #007bff;
  color: #fff;
}

.paginationBtns .paginationActive a:hover {
  background-color: #0069d9;
}

.paginationBtns .paginationDisabled a {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
} 
 




 


